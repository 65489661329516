const headerNavElMenu = document.querySelector('.header__nav-element_menu');
const dropDownMenu = document.querySelector('.drop-down-menu');
const arrow = document.querySelector('.header__link-arrow');

const headerBurger = document.querySelector('.header__burger');
const burger = document.querySelector('.burger');

//drop-down меню

// функция открытия и закрытия drop-down-меню
function toogleMenu(event) {
  dropDownMenu.classList.toggle('drop-down-menu_active');

  if (dropDownMenu.classList.contains('drop-down-menu_active')) {
    arrow.src = '/coolicon-up.svg';
    event.target.setAttribute('style', 'border-bottom: 1px solid #080E2C')
  } else {
    arrow.src = '/coolicon-down.svg';
    event.target.removeAttribute('style', 'border-bottom: 1px solid #080E2C')
  }
}
// функция закрытия меню по клику на затененный блок страницы
function closeMenu(event) {
  if (event.target.classList.contains('drop-down-menu__shadow')) {
    dropDownMenu.classList.remove('drop-down-menu_active');
    arrow.src = '/coolicon-down.svg';
    headerNavElMenu.removeAttribute('style', 'border-bottom: 1px solid #080E2C')
  }
}

// Бургер-меню
// функция открытия и закрытия бургер-меню

function toggleBurger(event) {
  burger.classList.toggle('burger_active');

  if (burger.classList.contains('burger_active')) {
    event.target.src = '/close.svg'
  } else {
    event.target.src = '/burger.svg'
  }
}
// слушатели клика
headerNavElMenu.addEventListener('click', toogleMenu);

headerBurger.addEventListener('click', toggleBurger);

document.body.addEventListener('click', closeMenu)

